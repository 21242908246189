import axios from 'axios';
import Axios from '@/axios';

import CompanyFactory from '@/core/Services/company';
import ActivityFactory from '@/core/Services/activity';

import { capitalizeSentence } from '@/helpers';

export default {
  async fetch({ commit }) {
    commit('SET_BUSY', 'fetch');

    const { data } = await Axios.get('activities');

    commit('SET_BUSY');
    commit('SET_ACTIVITIES', data);
  },

  async reset({ commit }, resetActivity = false) {
    if (resetActivity) {
      commit('SET_ACTIVITY');
    }

    commit('SET_LIST');
    commit('SET_NOT_FOUND');
    commit('SET_STATUS');
    commit('SET_REMOVE');
    commit('SET_PAGINATION');
    commit('SET_LOT_ID');
    commit('SET_FILTER');
    commit('SET_FORWARDED');
    commit('SET_IS_VALID_TO_FORWARD');
  },

  async sent({ commit, state }) {
    if (!state.activity) {
      return;
    }

    commit('SET_BUSY', 'sent');
    commit('SET_FORWARDED', false);
    try {
      const {
        remove,
        forwarded,
        searchCode: searchCode,
        search: search,
        pagination: { perPage, current_page },

        flagFilter,
        typeFilter,
        timeRangeFilter,
        timeRangeTypeFilter
      } = state;

      let timeRangeMax = null;
      let timeRangeMin = null;
      let timeRangeType = null;

      if (timeRangeFilter) {
        const [min = null, max = null] = timeRangeFilter.split(',');

        if (min && min !== 'null' && max && max !== 'null') {
          timeRangeMin = min;
          timeRangeMax = max;
          timeRangeType = timeRangeTypeFilter;
        }
      }

      const page = current_page - Math.floor(remove.length / perPage);
      const { data } = await Axios.get(
        `activities/${state.activity}/bill_activities/finished`,
        {
          params: {
            search,
            searchCode,
            page,
            perPage,
            onlyForwarded: forwarded,

            periodFilter:
              timeRangeMin && timeRangeMax
                ? JSON.stringify({
                    startDate: timeRangeMin,
                    endDate: timeRangeMax,
                    field: timeRangeType
                  })
                : null,
            flagFilter: flagFilter ? flagFilter.toString() : null,
            billTypeFilter: typeFilter ? typeFilter.toString() : null
          }
        }
      );

      const {
        content,
        totalPages: pages,
        totalElements: total,
        notFound
      } = data;

      commit('SET_REMOVE');
      commit('SET_NOT_FOUND', notFound);

      commit(
        'SET_LIST',
        content.map(({ patientName, ...list }) => ({
          ...list,
          patientName: capitalizeSentence(patientName)
        }))
      );
      commit('UPDATE_PAGINATION', {
        total,
        pages
      });
    } catch (e) {
      commit('SET_REMOVE');

      throw Promise.reject(e);
    } finally {
      commit('SET_BUSY');
    }
  },

  setRemovingActivities({ commit }, billActivityIds) {
    commit('SET_REMOVE', billActivityIds);
  },

  setLotId({ commit }, lotId) {
    commit('SET_LOT_ID', lotId);
  },

  // eslint-disable-next-line sonarjs/cognitive-complexity
  async bill({ commit, state }, resetPage = false) {
    /**
     * Skip if we do not have an activity.
     */
    if (!state.activity) {
      return;
    }

    commit('SET_BUSY', 'bill');

    try {
      const {
        remove,
        // @todo not working
        filter,
        search,
        searchCode,
        pagination: { perPage, current_page },
        responsibleFilter,
        orderFilter,
        flagFilter,
        typeFilter,
        timeRangeFilter,
        timeRangeTypeFilter
      } = state;

      let timeRangeMax = null;
      let timeRangeMin = null;
      let timeRangeType = null;

      if (timeRangeFilter) {
        const [min = null, max = null] = timeRangeFilter.split(',');

        if (min && min !== 'null' && max && max !== 'null') {
          timeRangeMin = min;
          timeRangeMax = max;
          timeRangeType = timeRangeTypeFilter;
        }
      }

      const auxFilter = ['SLA', 'SENT_TO_HEALTH_INSURANCE'].includes(filter)
        ? null
        : filter;
      const sentToHealthInsurance = filter === 'SENT_TO_HEALTH_INSURANCE';
      const page = resetPage
        ? 1
        : current_page - Math.floor(remove.length / perPage);

      commit('SET_ERRORS', []);
      commit('SET_NOT_FOUND');

      if (state.cancelToken != null) {
        commit('SET_HAVE_CANCELLED', true);
        state.cancelToken.cancel();
      }
      const source = axios.CancelToken.source();
      commit('SET_CANCEL_TOKEN', source);
      const response = await ActivityFactory.getBillActivities(
        state.activity,
        {
          search,
          searchCode,
          cardPropertyFilter: auxFilter,
          page,
          perPage,
          responsibleFilter: responsibleFilter.length
            ? responsibleFilter
            : null,
          sentToHealthInsurance,
          periodFilter:
            timeRangeMin && timeRangeMax
              ? JSON.stringify({
                  startDate: timeRangeMin,
                  endDate: timeRangeMax,
                  field: timeRangeType
                })
              : null,
          orderBy: orderFilter ? orderFilter.toString() : null,
          flagFilter: flagFilter ? flagFilter.toString() : null,
          billTypeFilter: typeFilter ? typeFilter.toString() : null
        },
        source.token
      );

      if (!response) {
        return;
      }

      const {
        content,
        totalPages: pages,
        totalElements: total,
        totalCards,
        totalCardsOk: success,
        totalCardsWarning: warn,
        totalCardsDelayed: danger,
        totalAmount,
        notFound
      } = response;

      commit('SET_REMOVE');
      commit('SET_NOT_FOUND', notFound);
      commit('SET_STATUS', {
        warn,
        danger,
        success,
        total: totalCards,
        totalAmount
      });

      commit(
        'SET_LIST',
        content.map(({ patientName, ...card }) => ({
          ...card,
          patientName: capitalizeSentence(patientName)
        }))
      );
      commit('UPDATE_PAGINATION', {
        total,
        pages
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        commit('SET_ERRORS', [err?.response?.data?.message]);
      }
    } finally {
      if (state.haveCancelled) {
        commit('SET_HAVE_CANCELLED', false);
      } else {
        commit('SET_BUSY');
        commit('SET_CANCEL_TOKEN', null);
      }
    }
  },
  selectBillActivity({ commit }, billActivityId) {
    commit('SELECT', billActivityId);
  },
  selectBillActivityFromBill({ commit }, bill) {
    commit('SELECT_FROM_OBJECT', bill);
  },
  removeSelectedBillActivity({ commit }, index) {
    commit('REMOVE_SELECTED', index);
  },
  clearSelecteds({ commit }) {
    commit('SET_SEND_TO');
    commit('SET_SELECTED');
  },
  clearSelectedActivities({ commit }) {
    commit('SET_SELECTED');
  },
  setSendTo({ commit }, id) {
    commit('SET_SEND_TO', id);
  },
  setListBackward({ commit }, listBackward) {
    commit('SET_LIST_BACKWARD', listBackward);
  },
  setActivity({ commit }, activityId) {
    commit('SET_ACTIVITY', activityId);
  },
  toggleListSelected({ commit }, { list, isAllChecked }) {
    if (isAllChecked) {
      commit('REMOVE_SELECTEDS_FROM_ID', list);
      return;
    }

    commit('SELECT_MULTIPLE', list);
  },
  setIsValidToForward({ commit }, isValidToForward) {
    commit('SET_IS_VALID_TO_FORWARD', isValidToForward);
  },
  setSearch({ commit }, searchQueries = []) {
    commit('SET_SEARCH', searchQueries[0]?.searchString ?? '');
    commit('SET_SEARCH_CODE', searchQueries[0]?.searchCode ?? '');
  },
  setPage({ commit }, pageNumber = 1) {
    commit('SET_PAGE', pageNumber);
  },
  setErrors({ commit }, errors = []) {
    commit('SET_ERRORS', errors);
  },
  async getSearchCodeOptions({ commit }) {
    const result = await CompanyFactory.getBillActivityAdvancedSearches();
    commit('SET_SEARCH_CODE_OPTIONS', result);
  },
  setResponsible({ commit }, { user, billActivityId }) {
    commit('SET_RESPONSIBLE', { user, billActivityId });
  },
  setFilter({ commit }, filter) {
    commit('SET_FILTER', filter);
  },
  setResponsibleFilter({ commit }, responsibleFilter) {
    commit('SET_RESPONSIBLE_FILTER', responsibleFilter);
  },
  setOrderFilter({ commit }, orderFilter) {
    commit('SET_ORDER_FILTER', orderFilter);
  },
  setTimeRangeFilter({ commit }, timeRangeFilter) {
    commit('SET_TIMERANGE_FILTER', timeRangeFilter);
  },
  setTimeRangeTypeFilter({ commit }, timeRangeTypeFilter) {
    commit('SET_TIMERANGE_TYPE_FILTER', timeRangeTypeFilter);
  },
  setFlagFilter({ commit }, flagFilter) {
    commit('SET_FLAG_FILTER', flagFilter);
  },
  setTypeFilter({ commit }, typeFilter) {
    commit('SET_TYPE_FILTER', typeFilter);
  },
  removeItemNotFound({ commit }, { searchCode, searchValue }) {
    commit('REMOVE_SEARCH_ITEM', searchValue);
    commit('REMOVE_NOT_FOUND_ITEM', { searchCode, searchValue });
  }
};
